import { computed, watch, onBeforeMount, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import AppPostList from '@/components/AppPostPreviewList/AppPostPreviewList.vue'
import AppWidgetSocial from '@/components/AppWidgetSocial/AppWidgetSocial.vue'
import AppWidgetLatestVideo from '@/components/AppWidgetLatestVideo/AppWidgetLatestVideo.vue'
import AppWidgetLatestPosts from '@/components/AppWidgetLatestPosts/AppWidgetLatestPosts.vue'
import { GA } from '@/modules/googleAnalytics'
import DOCUMENT_TITLE from '@/constants/documentTitles'

export default {
    name: 'PostFeed',
    components: {
        AppPostList,
        AppWidgetSocial,
        AppWidgetLatestVideo,
        AppWidgetLatestPosts
    },
    props: ['page', 'tag'],
    setup(props) {
        const route = useRoute()
        const pageNumber = computed(() => props.page ?? 1)
        const setPageTitle = () => {
            document.title = `${DOCUMENT_TITLE.DEFAULT} | ${DOCUMENT_TITLE.FEED} – ${pageNumber.value}`
        }
        onBeforeMount(() => {
            setPageTitle()
            GA.trackPage(route.path)
        })
        onBeforeUnmount(() => {
            document.title = DOCUMENT_TITLE.DEFAULT
        })
        watch(pageNumber, () => {
            setPageTitle()
            GA.trackPage(route.path)
        })
        return {
            pageNumber
        }
    }
}

<template>
    <div class="cookie-customise__body">
        <AppCookieAccordion
            :init-switch-value="cookiePreferences[COOKIES_OPERATIONAL_KEY]"
            :disabled-switch="true"
        >
            <template #title>
                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_OPERATIONAL_TITLE') }}
            </template>
            <template #body>
                <table>
                    <caption>
                        {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_OPERATIONAL_DESC') }}
                    </caption>
                    <thead>
                        <tr>
                            <th>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_TABLE_COLUMN_ONE_TITLE') }}
                            </th>
                            <th>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_TABLE_COLUMN_TWO_TITLE') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>cookies_preferences</td>
                            <td>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_OPERATIONAL_TABLE_ROW_DESC') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </AppCookieAccordion>
        <AppCookieAccordion
            :init-switch-value="cookiePreferences[COOKIES_ADVERTISING_KEY]"
            :default-collapsed="false"
            @update-switch-value="changeAdvertisingPreferences"
        >
            <template #title>
                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_ADVERTISING_TITLE') }}
            </template>
            <template #body>
                <p>
                    {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_OPERATIONAL_NOTIFY_TEXT') }}
                    <a :href="URL.COOKIE_POLICY" target="_blank">
                        {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_OPERATIONAL_NOTIFY_LINK') }}
                    </a>
                </p>
                <table>
                    <caption>
                        {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_ADVERTISING_DESC') }}
                    </caption>
                    <thead>
                        <tr>
                            <th>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_TABLE_COLUMN_ONE_TITLE') }}
                            </th>
                            <th>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_TABLE_COLUMN_TWO_TITLE') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>_ga</td>
                            <td>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_ADVERTISING_TABLE_ROW_DESC') }}
                            </td>
                        </tr>
                        <tr>
                            <td>_ga_K4JXS4D55Z</td>
                            <td>
                                {{ i18n.$t('COOKIE_CUSTOMISE_ACCORDION_ADVERTISING_TABLE_ROW_DESC') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </AppCookieAccordion>
    </div>
</template>

<script setup>
import AppCookieAccordion from '@/components/AppCookieDisclaimer/elements/AppCookieAccordion.vue'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'
import { COOKIES_OPERATIONAL_KEY, COOKIES_ADVERTISING_KEY } from '@/constants/cookies'
import URL from '@/constants/url'
import { useI18n } from '@/plugins/i18nPlugin'

const { cookiePreferences, changeAdvertisingPreferences } = useCookiesModal()

const i18n = useI18n()
</script>

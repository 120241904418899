import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from '@/plugins/i18nPlugin'
import ACTION_TYPES from '@/store/actions/types'
import iconYoutube from '@/assets/svg/iconYoutube.svg'

export default {
    name: 'LatestVideo',
    components: {
        iconYoutube
    },
    setup() {
        const i18n = useI18n()
        const store = useStore()

        onMounted(async() => {
            await store.dispatch(ACTION_TYPES.GET_LATEST_VIDEO)
        })

        const videoObject = computed(() => store.state.latestVideo)
        const videoUrl = computed(() => {
            return (videoObject.value) ? videoObject.value.urlVideo : ''
        })
        const previewUrl = computed(() => {
            return (videoObject.value) ? videoObject.value.urlPreview : ''
        })
        const title = computed(() => {
            return (videoObject.value) ? videoObject.value.title : ''
        })
        const description = computed(() => {
            return (videoObject.value) ? videoObject.value.description : ''
        })

        return {
            i18n,
            videoUrl,
            previewUrl,
            title,
            description
        }
    }
}

export default {
    API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
    HERO_WARS_HOME: 'https://www.hero-wars.com',
    PRIVACY_POLICY: 'https://www.hero-wars.com/policy/privacy/en',
    COOKIE_POLICY: '/cookie-policy',
    TERMS_OF_SERVICES: 'https://www.hero-wars.com/policy/terms/en',
    SUPPORT: 'https://community.hero-wars.com/support',
    DISCORD: 'https://discord.com/invite/herowars',
    YOUTUBE: 'https://www.youtube.com/c/HeroWars?sub_confirmation=1'
}

<div class="widget-posts" v-if="!isEmpty">
    <p class="widget-posts__name">{{ i18n.$t('WIDGET_LATEST_POSTS_NAME') }}</p>
    <a
        @click="handlePreviewClicked(post.id, post.title)"
        class="widget-posts__one-post"
        :class="{ 'widget-posts__one-post_active': post.selected }"
        v-for="post in posts"
        :key="post.id"
    >
        <img
            class="widget-posts__image"
            :src="post.image"
            alt="post-preview-image"
        >
        <div class="widget-posts__text-wrapper">
            <p class="widget-posts__date">{{ post.created }}</p>
            <p class="widget-posts__title">{{ post.title }}</p>
        </div>
    </a>
</div>

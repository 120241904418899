import MUTATION_TYPES from './types'
import setPostList from './SetPostList'
import setPostListIsLoading from './SetPostListIsLoading'
import setPostTotalNumber from './SetPostTotalNumber'
import setCurrentPost from './SetCurrentPost'
import setCurrentPostIsLoading from './SetCurrentPostIsLoading'
import setLatestVideo from './SetLatestVideo'
import setLatestPosts from './SetLatestPosts'
import setTags from './SetTags'

export default {
    [MUTATION_TYPES.SET_POST_LIST]: setPostList,
    [MUTATION_TYPES.SET_POST_LIST_IS_LOADING]: setPostListIsLoading,
    [MUTATION_TYPES.SET_POST_TOTAL_NUMBER]: setPostTotalNumber,
    [MUTATION_TYPES.SET_CURRENT_POST]: setCurrentPost,
    [MUTATION_TYPES.SET_CURRENT_POST_IS_LOADING]: setCurrentPostIsLoading,
    [MUTATION_TYPES.SET_LATEST_VIDEO]: setLatestVideo,
    [MUTATION_TYPES.SET_LATEST_POSTS]: setLatestPosts,
    [MUTATION_TYPES.SET_TAGS]: setTags
}

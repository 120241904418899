import client from './Client'

const END_POINT = 'api/posts'

// получение поста по id
const getPost = (postId) => client.get(`${END_POINT}/${postId}`)

// получение списка последних постов
const getLatestPosts = (limit) => client.get(`${END_POINT}/limit/${limit}`)

// получение списка (страницы) опубликованных постов
const getPostList = (page) => client.get(`${END_POINT}/published?page=${page}`)

export {
    getPost,
    getLatestPosts,
    getPostList
}

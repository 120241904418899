<div class="error-404">
    <h2 class="error-404__title">{{ i18n.$t('404_TITLE') }}</h2>
    <p class="error-404__text">{{ i18n.$t('404_TEXT') }}</p>
    <app-button
        size="normal"
        type="primary"
        :text="i18n.$t('404_BUTTON')"
        @click="handleMainPageClicked"
    >
    </app-button>
</div>

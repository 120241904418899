import { computed } from 'vue'
import Moment from 'moment'

export default function formatPostDate(time, format) {
    const postDate = computed(() => {
        if (!time.value) return ''
        const postMoment = new Moment(time.value * 1000)
        return postMoment.format(format)
    })

    return {
        postDate
    }
}

<template>
    <div
        class="cookie-accordion"
        :data-collapsed="isCollapsed"
    >
        <div class="cookie-accordion__header">
            <h4
                class="cookie-accordion__title"
                @click="toggleCollapse"
            >
                <IconAngle
                    class="cookie-accordion__icon"
                />
                <slot name="title" />
            </h4>
            <AppCheckbox
                v-model:modelValue="switchValue"
                :disabled="disabledSwitch"
                :style="CHECKBOX_STYLE.SWITCH"
                @change="updateSwitchValue"
            />
        </div>
        <div class="cookie-accordion__body">
            <div
                ref="accordionContent"
                class="cookie-accordion__content"
            >
                <slot name="body" />
            </div>
        </div>
    </div>
</template>

<script setup>
import AppCheckbox from '@/components/AppCheckbox.vue'
import IconAngle from '@/assets/svg/iconDisclaimerAngleRight.svg'
import CHECKBOX_STYLE from '@/constants/input'
import { defineProps, defineEmits, ref, onMounted, computed } from 'vue'

const emit = defineEmits(['update-switch-value'])

const props = defineProps({
    initSwitchValue: {
        type: Boolean,
        required: true,
    },
    defaultCollapsed: {
        type: Boolean,
        required: false,
        default: true,
    },
    switchState: {
        type: Boolean,
        required: false,
        default: true,
    },
    disabledSwitch: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const isCollapsed = ref(props.defaultCollapsed)
const switchValue = ref(props.initSwitchValue)
const accordionContent = ref(null)
const accordionContentHeight = ref(null)

onMounted(() => {
    accordionContentHeight.value = accordionContent.value.clientHeight
})

const accordionBodyHeight = computed(() => {
    return `${isCollapsed.value ? 0 : accordionContentHeight.value}px`
})

function toggleCollapse() {
    isCollapsed.value = !isCollapsed.value
}

function updateSwitchValue() {
    emit('update-switch-value', switchValue.value)
}
</script>

<style lang="scss" scoped>
    .cookie-accordion__body {
        height: v-bind(accordionBodyHeight)
    }
</style>

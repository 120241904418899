import { getTags } from '@/api/UserTags'
import MUTATION_TYPES from '@/store/mutations/types'

export default async({ commit }) => {
    try {
        const result = await getTags()
        commit(MUTATION_TYPES.SET_TAGS, result.data.data)
        return result.data.data
    } catch (error) {
        console.error(error)
    }
}

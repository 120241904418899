<div class="widget-video">
    <p class="widget-video__name">{{ i18n.$t('WIDGET_LATEST_VIDEO_NAME') }}</p>
    <a
        target="_blank"
        :href="videoUrl"
    >
        <p class="widget-video__title">{{ title }}</p>
        <p class="widget-video__description">{{ description }}</p>

        <div class="widget-video__preview">
            <img
                class="widget-video__image"
                :src="previewUrl"
                alt="video-preview-image"
            >
            <div class="widget-video__image-overlay"></div>
            <icon-youtube class="widget-video__icon"></icon-youtube>
        </div>
    </a>
</div>

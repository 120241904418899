import axios from 'axios'
import URL from '@/constants/url'
import * as Sentry from '@sentry/vue'
import { ERRORS } from '@/constants/keycloakAuth'
import Keycloak from '@/composables/setupKeycloak'

const httpClient = axios.create({
    baseURL: URL.API_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
})

// перехватчик ошибок
const errorInterceptor = error => {
    // если ошибка на сервере
    if (!error.response) {
        console.error('Network/Server error', error)
        return Promise.reject(error)
    }

    console.error(error.response.status, error.message)
    // разбираем коды ответа
    if ((error.response.status < 400) || (error.response.status >= 500)) {
        Sentry.captureException(error)
    }
    const isExpiredToken = error.response?.data?.errors?.some(error => error.detail === ERRORS.EXPIRED_TOKEN)
    if (isExpiredToken) Keycloak.forceUpdateToken()

    return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = response => {
    switch (response.status) {
        case 200:
        // все ок
            break
        // todo
        default:
        // todo
    }

    return response
}

export const setAuthHeader = (token) => {
    httpClient.defaults.headers['Authorization'] = `Bearer ${token}`
}

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor)

export default httpClient

<app-post-list
    :page="pageNumber"
    :tag="tag"
>
</app-post-list>
<div class="widget-wrapper">
    <!-- <app-widget-latest-video></app-widget-latest-video> -->
    <div class="sticky sticky_top">
        <app-widget-latest-posts></app-widget-latest-posts>
        <app-widget-social></app-widget-social>
    </div>
</div>

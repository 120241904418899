<template>
    <AppCookiePolicy />
    <div class="widget-wrapper">
        <div class="sticky sticky_top">
            <AppWidgetLatestPosts />
            <AppWidgetSocial />
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import AppCookiePolicy from '@/components/AppCookiePolicy.vue'
import AppWidgetSocial from '@/components/AppWidgetSocial/AppWidgetSocial.vue'
import AppWidgetLatestPosts from '@/components/AppWidgetLatestPosts/AppWidgetLatestPosts.vue'
import DOCUMENT_TITLE from '@/constants/documentTitles'
import { GA } from '@/modules/googleAnalytics'

const route = useRoute()
onBeforeMount(() => {
    document.title = DOCUMENT_TITLE.COOKIE_POLICY
    GA.trackPage(route.path)
})
onBeforeUnmount(() => {
    document.title = DOCUMENT_TITLE.DEFAULT
})

</script>

<div class="pagination">
    <button
        class="pagination__button pagination__button_enabled"
        v-if="!buttonPrevHidden"
        @click="handlePrevClicked"
    >
        <icon-angle-left></icon-angle-left>
    </button>
    <button
        class="pagination__button"
        :class="{
                    'pagination__button_enabled': !page.disabled,
                    'pagination__button_disabled': page.disabled,
                    'pagination__button_selected': page.selected
                }"
        :disabled="page.disabled"
        v-for="page in pagesView"
        :key="page"
        @click="handlePageClicked(page)"
    >
        {{ page.text }}
    </button>
    <button
        class="pagination__button pagination__button_enabled"
        v-if="!buttonNextHidden"
        @click="handleNextClicked"
    >
        <icon-angle-right></icon-angle-right>
    </button>
</div>

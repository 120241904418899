export default {
    GET_POST: 'GET_POST',
    CREATE_POST: 'CREATE_POST',
    UPDATE_POST: 'UPDATE_POST',
    DELETE_POST: 'DELETE_POST',
    GET_DELAYED_POSTS: 'GET_DELAYED_POSTS',
    GET_POST_LIST: 'GET_POST_LIST',
    UPLOAD_IMAGE_FILE: 'UPLOAD_IMAGE_FILE',
    GET_TAGS: 'GET_TAGS',
    LOGIN: 'LOGIN',
}

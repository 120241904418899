<template>
    <div class="cookie-customise__footer">
        <button
            class="button cookie-customise__button--ghost"
            @click="setCookiesPreferences"
        >
            {{ i18n.$t('COOKIE_CUSTOMISE_SAVE') }}
        </button>
        <button
            class="button button_primary button_small cookie-customise__button--primary"
            @click="acceptAllCookies"
        >
            {{ i18n.$t('COOKIE_ACCEPT_ALL_BUTTON') }}
        </button>
    </div>
</template>

<script setup>
import { useI18n } from '@/plugins/i18nPlugin'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'

const {
    setCookiesPreferences,
    acceptAllCookies,
} = useCookiesModal()

const i18n = useI18n()
</script>

import client from './Client'

const END_POINT = 'api/admin/posts'

// создание нового поста
const createPost = (params) => client.post(`${END_POINT}`, params)

// редактирование существующего поста
const updatePost = (params, postId) => client.put(`${END_POINT}/${postId}`, params)

// получение поста по id - будут дополнительные поля по сравнению с пользовательским методом
const getPost = (postId) => client.get(`${END_POINT}/${postId}`)

// удаление поста по id
const deletePost = (postId) => client.delete(`${END_POINT}/${postId}`)

// получение списка (страницы) опубликованных постов
const getPostList = (page) => client.get(`${END_POINT}/published?page=${page}`)

// получение всех отложенных постов
const getDelayedPosts = () => client.get(`${END_POINT}/delayed`)

export {
    getPost,
    updatePost,
    createPost,
    deletePost,
    getDelayedPosts,
    getPostList
}

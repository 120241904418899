import { getLatestPosts } from '@/api/UserPosts'
import MUTATION_TYPES from '@/store/mutations/types'
import POST_CONSTANTS from '@/constants/posts'

export default async({ commit }) => {
    try {
        const result = await getLatestPosts(POST_CONSTANTS.POSTS_IN_WIDGET)
        commit(MUTATION_TYPES.SET_LATEST_POSTS, result.data.data)
        return result.data.posts
    } catch (error) {
        console.error(error)
    }
}

import { getLatestVideo } from '@/api/LatestVideo'
import MUTATION_TYPES from '@/store/mutations/types'

export default async({ commit }) => {
    try {
        const result = await getLatestVideo()
        commit(MUTATION_TYPES.SET_LATEST_VIDEO, result.data)
        return result.data
    } catch (error) {
        console.error(error)
    }
}

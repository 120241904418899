export default {
    SET_POST_LIST: 'SET_POST_LIST',
    SET_POST_LIST_IS_LOADING: 'SET_POST_LIST_IS_LOADING',
    SET_POST_TOTAL_NUMBER: 'SET_POST_TOTAL_NUMBER',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_CURRENT_POST: 'SET_CURRENT_POST',
    SET_CURRENT_POST_IS_LOADING: 'SET_CURRENT_POST_IS_LOADING',
    SET_LATEST_VIDEO: 'SET_LATEST_VIDEO',
    SET_LATEST_POSTS: 'SET_LATEST_POSTS',
    SET_TAGS: 'SET_TAGS'
}

<template>
    <div
        class="checkbox"
        :class="checkboxClassObj"
        :tabindex="tabIndex"
        @keyup.enter="handleEnter"
    >
        <label class="checkbox__label">
            <input
                class="checkbox__input"
                :disabled="disabled"
                type="checkbox"
                :checked="modelValue"
                tabindex="-1"
                @input="handleInput"
            >
            <span class="checkbox__slider" />
            <span class="checkbox__text">{{ label }}</span>
        </label>
    </div>
</template>

<script>
import { computed } from 'vue'
import CHECKBOX_STYLE from '@/constants/input'
export default {
    name: 'AppCheckbox',
    props: {
        label: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: {
            type: Boolean,
            default: null
        },
        style: {
            type: String,
            default: CHECKBOX_STYLE.CHECKBOX
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const handleInput = (e) => emit('update:modelValue', e.target.checked)
        const handleEnter = () => emit('update:modelValue', !props.modelValue)
        const checkboxClassObj = computed(() => {
            return {
                'checkbox_disabled': props.disabled,
                'checkbox_default': props.style === CHECKBOX_STYLE.CHECKBOX,
                'checkbox_switch': props.style === CHECKBOX_STYLE.SWITCH
            }
        })
        const tabIndex = computed(() => props.disabled ? '-1' : '0')
        return {
            handleInput,
            handleEnter,
            checkboxClassObj,
            tabIndex
        }
    }
}
</script>

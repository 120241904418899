<template>
    <header class="header">
        <a
            class="header__link header__logo"
            @click="handleLogoClicked"
        >
            <img
                class="header__logo-img"
                :src="LogoText"
                alt="hw-logo"
            >
        </a>
        <div class="header__tags">
            <router-link
                class="header__link header__tag"
                :class="getTagClassObj(defaultTitle)"
                :to="getTagPath(defaultSlug)"
                @click="handleTagClicked(defaultTitle)"
            >
                ALL
            </router-link>
            <router-link
                v-for="tag in tags"
                :key="tag.id"
                class="header__link header__tag"
                :class="getTagClassObj(tag.attributes.title)"
                :to="getTagPath(tag.attributes.slug)"
                @click="handleTagClicked(tag.attributes.title)"
            >
                {{ tag.attributes.title }}
            </router-link>
        </div>
    </header>
</template>

<script>
export default {
    name: 'AppHeader',
}
</script>

<script setup>
import { computed, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL } from '@/constants/analytics'
import ACTION_TYPES from '@/store/actions/types'
import TAG_CONSTANTS from '@/constants/tags'
import { STATIC_RESOURCES_PATH } from '@/constants/envAppConfig'

const LogoText = `${STATIC_RESOURCES_PATH}images/hw-logo-text-160x56-en-@4x.png`

const router = useRouter()
const store = useStore()

const defaultTitle = TAG_CONSTANTS.DEFAULT_FEED_TAG.title
const defaultSlug = TAG_CONSTANTS.DEFAULT_FEED_TAG.slug

onBeforeMount(async() => {
    await store.dispatch(ACTION_TYPES.GET_TAGS)
})

const currentRouteTag = computed(() => useRoute().params.tag || '')
const tags = computed(() => store.state.tags)

const isCurrentTagSimilarTo = (tagName) => {
    return tagName.toLowerCase() === currentRouteTag.value.toLowerCase()
}

const getTagClassObj = (tagName) => {
    return {
        header__tag_selected: isCurrentTagSimilarTo(tagName)
    }
}

const getTagPath = (tagSlug) => `/feed/${tagSlug}`

const { LINK } = EVENT_ACTION
const { NAVIGATION } = EVENT_CATEGORY
const handleLogoClicked = async() => {
    GA.trackEvent(LINK, NAVIGATION, EVENT_LABEL.HW_LOGO)
    await router.push('/')
}
const handleTagClicked = (tagName) => GA.trackEvent(LINK, NAVIGATION, tagName)
</script>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import setupSentry from '@/composables/setupSentry'
import { IS_DEVELOPMENT_MODE } from '@/constants/envAppConfig'
import { GA } from '@/modules/googleAnalytics'
import { isEnableGA } from '@/services/cookiesManager'

const app = createApp(App).use(store).use(router)

if (isEnableGA()) GA.init()

if (!IS_DEVELOPMENT_MODE) {
    setupSentry(app)
}

app.mount('#app')

<template>
    <div class="cookie-customise__header">
        <h3 class="cookie-customise__title">
            {{ i18n.$t('COOKIE_CUSTOMISE_TITLE') }}
        </h3>
        <button
            class="button button_primary cookie-customise__close"
            @click="closeDisclaimerHandler"
        >
            <IconClose />
        </button>
    </div>
</template>

<script setup>
import IconClose from '@/assets/svg/iconDisclaimerClose.svg'
import { useI18n } from '@/plugins/i18nPlugin'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'

const i18n = useI18n()

const {
    closeDisclaimerHandler,
} = useCookiesModal()

</script>

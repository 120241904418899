<template>
    <div class="post post_view">
        <AppTagPopover :tags="postTags" />
        <img
            v-if="!postIsLoading"
            class="post__image_main"
            :src="postWallpaperUrl"
            alt="picture-main"
        >
        <div
            v-else
            class="post__image_main skeleton"
        >
            <div class="skeleton__loader" />
        </div>
        <div
            class="post__content"
            @click="handlePostClicked"
        >
            <template v-if="!postIsLoading">
                <h1 class="post__title">
                    {{ postTitle }}
                </h1>
                <app-post-block
                    v-for="(block, i) in postBlocks"
                    :key="i"
                    :block="block"
                />
                <p class="post__date">
                    {{ i18n.$t('POSTED_ON') }} {{ postDate }}
                </p>
            </template>
            <template v-else>
                <div class="skeleton skeleton_post-title">
                    <div class="skeleton__loader" />
                </div>
                <div class="skeleton skeleton_post-title skeleton_post-title_short">
                    <div class="skeleton__loader" />
                </div>
                <div class="skeleton skeleton_post-text">
                    <div class="skeleton__loader" />
                </div>
                <div class="skeleton skeleton_post-text skeleton_post-text_short">
                    <div class="skeleton__loader" />
                </div>
                <div class="skeleton skeleton_post-text">
                    <div class="skeleton__loader" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppPost',
}
</script>

<script setup>
import { useI18n } from '@/plugins/i18nPlugin'
import { computed, watch, onBeforeMount, onBeforeUnmount, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import ACTION_TYPES from '@/store/actions/types'
import AppPostBlock from '@/components/AppPost/AppPostBlock/AppPostBlock.vue'
import AppTagPopover from '@/components/AppTagPopover.vue'
import formatPostDate from '@/composables/formatPostDate'
import DOCUMENT_TITLE from '@/constants/documentTitles'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY } from '@/constants/analytics'

const props = defineProps({
    postId: {
        type: [String],
        default: null
    }
})

const store = useStore()
const router = useRouter()
const route = useRoute()
const i18n = useI18n()

const postObject = computed(() => store.state.currentPost)
const postIsLoading = computed(() => store.state.currentPostIsLoading)
const postWallpaperUrl = computed(() => postObject.value?.attributes?.wallpaper ?? '')
const postBlocks = computed(() => postObject.value?.attributes?.body ?? [])
const postTitle = computed(() => postObject.value?.attributes?.title ?? '')
const postTags = computed(() => postObject.value?.relationships?.tags?.data || [])
const publicationDate = computed(() => postObject.value?.attributes?.publication_date ?? null)
const { postDate } = formatPostDate(publicationDate, i18n.$t('DATE_FORMAT'))

const loadPost = async() => {
    const data = await store.dispatch(ACTION_TYPES.GET_POST, { id: props.postId })
    if (!data.success) {
        await router.replace({
            name: '404'
        })
    } else {
        document.title = postTitle.value
        GA.trackPage(route.path)
    }
    window.scrollTo(0, 0)
}

const handlePostClicked = (event) => {
    if (event.target.tagName.toLowerCase() === 'a') {
        GA.trackEvent(EVENT_ACTION.LINK, EVENT_CATEGORY.EXIT, event.target.textContent)
    }
}

onBeforeMount(async() => {
    await loadPost()
})

onBeforeUnmount(() => {
    document.title = DOCUMENT_TITLE.DEFAULT
})

watch(props, loadPost)
</script>

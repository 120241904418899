import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from '@/plugins/i18nPlugin'
import formatPostDate from '@/composables/formatPostDate'
import { GA } from '@/modules/googleAnalytics'
import ACTION_TYPES from '@/store/actions/types'
import { EVENT_ACTION, EVENT_CATEGORY } from '@/constants/analytics'

export default {
    name: 'LatestPosts',
    props: {
        detectCurrent: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const i18n = useI18n()
        const store = useStore()
        const router = useRouter()

        const openedPostId = computed(() => (store.state.currentPost) ? store.state.currentPost.id : null)
        const latestPosts = computed(() => store.state.latestPosts || [])
        const isEmpty = computed(() => latestPosts.value.length === 0)
        const posts = computed(() => {
            return latestPosts.value.map(post => {
                const publicationDate = computed(() => post.attributes.publication_date)
                const { postDate } = formatPostDate(publicationDate, i18n.$t('DATE_FORMAT'))
                return {
                    id: post.id,
                    title: post.attributes.title,
                    image: post.attributes.wallpaper,
                    created: postDate.value,
                    selected: (props.detectCurrent) ? openedPostId.value === post.id : false
                }
            })
        })

        onMounted(async() => {
            await store.dispatch(ACTION_TYPES.GET_LATEST_POSTS)
        })

        const handlePreviewClicked = async(postId, postTitle) => {
            GA.trackEvent(EVENT_ACTION.LINK, EVENT_CATEGORY.NAVIGATION, postTitle)
            await router.push(`/post/${postId}`)
        }

        return {
            i18n,
            posts,
            openedPostId,
            isEmpty,
            handlePreviewClicked
        }
    }
}

import { computed } from 'vue'
import BLOCK_TYPE from '@/constants/blocks'
import AppPostHeader from '@/components/AppPost/AppPostHeader/AppPostHeader.vue'
import AppPostParagraph from '@/components/AppPost/AppPostParagraph/AppPostParagraph.vue'
import AppPostImage from '@/components/AppPost/AppPostImage/AppPostImage.vue'
import AppPostEmbed from '@/components/AppPost/AppPostEmbed/AppPostEmbed.vue'
import AppPostList from '@/components/AppPost/AppPostList/AppPostList.vue'

export default {
    name: 'Block',
    components: {
        AppPostHeader,
        AppPostParagraph,
        AppPostImage,
        AppPostEmbed,
        AppPostList
    },
    props: {
        block: Object
    },
    setup(props) {
        const isHeader = computed(() => props.block.type === BLOCK_TYPE.HEADER)
        const isParagraph = computed(() => props.block.type === BLOCK_TYPE.PARAGRAPH)
        const isList = computed(() => props.block.type === BLOCK_TYPE.LIST)
        const isImage = computed(() => props.block.type === BLOCK_TYPE.IMAGE)
        const isEmbed = computed(() => props.block.type === BLOCK_TYPE.EMBED)

        return {
            isHeader,
            isParagraph,
            isList,
            isImage,
            isEmbed
        }
    }
}

import { onBeforeMount, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { GA } from '@/modules/googleAnalytics'
import DOCUMENT_TITLE from '@/constants/documentTitles'
import AppWidgetSocial from '@/components/AppWidgetSocial/AppWidgetSocial.vue'
import AppWidgetLatestVideo from '@/components/AppWidgetLatestVideo/AppWidgetLatestVideo.vue'
import AppWidgetLatestPosts from '@/components/AppWidgetLatestPosts/AppWidgetLatestPosts.vue'
import AppPageNotFound from '@/components/AppPageNotFound/AppPageNotFound.vue'

export default {
    name: 'View404',
    components: {
        AppPageNotFound,
        AppWidgetSocial,
        AppWidgetLatestVideo,
        AppWidgetLatestPosts
    },
    setup() {
        const route = useRoute()
        onBeforeMount(async() => {
            document.title = DOCUMENT_TITLE.NOT_FOUND
            GA.trackPage(route.path)
        })
        onBeforeUnmount(() => {
            document.title = DOCUMENT_TITLE.DEFAULT
        })
        return {
        }
    }
}

import client from './Client'

const END_POINT = 'api/tags'

// получение списка тегов
const getTags = () => client.get(END_POINT)

// загрузка списка (страницы) опубликованный постов по тегу
const getPostListWithTag = (page, tagSlug) => client.get(`${END_POINT}/${tagSlug}/posts?page=${page}`)

export {
    getTags,
    getPostListWithTag
}

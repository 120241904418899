<template>
    <footer class="footer">
        <p class="footer__info">
            {{ i18n.$t('FOOTER_INFO_MESSAGE') }}
        </p>
        <img
            class="footer__logo"
            :src="logoImagePath"
            alt="logo-hw"
        >
        <div class="footer__links">
            <button
                class="footer__button"
                @click="showCookiesDisclaimer"
            >
                {{ i18n.$t('FOOTER_COOKIE_SETTINGS') }}
            </button>
            <a
                class="footer__one-link"
                :href="URL.SUPPORT"
                target="_blank"
                @click="handleLinkSupportClicked"
            >
                {{ i18n.$t('FOOTER_SUPPORT') }}
            </a>
            <a
                class="footer__one-link"
                :href="URL.PRIVACY_POLICY"
                target="_blank"
                @click="handleLinkPrivacyClicked"
            >
                {{ i18n.$t('FOOTER_PRIVACY') }}
            </a>
            <a
                class="footer__one-link"
                :href="URL.TERMS_OF_SERVICES"
                target="_blank"
                @click="handleLinkTermsClicked"
            >
                {{ i18n.$t('FOOTER_TERMS') }}
            </a>
            <a
                :href="URL.COOKIE_POLICY"
                target="_blank"
                class="footer__one-link"
            >
                {{ i18n.$t('FOOTER_COOKIE_POLICY') }}
            </a>
        </div>
    </footer>
</template>
<script setup>
import { useI18n } from '@/plugins/i18nPlugin'
import URL from '@/constants/url'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL } from '@/constants/analytics'
import { STATIC_RESOURCES_PATH } from '@/constants/envAppConfig'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'

const { showCookiesDisclaimer } = useCookiesModal()

const i18n = useI18n()
const logoImagePath = `${STATIC_RESOURCES_PATH}images/hw-logo-full-160x144-en-@4x.png`

const { LINK } = EVENT_ACTION
const { EXIT } = EVENT_CATEGORY
const handleLinkSupportClicked = () => GA.trackEvent(LINK, EXIT, EVENT_LABEL.LINK_SUPPORT)
const handleLinkPrivacyClicked = () => GA.trackEvent(LINK, EXIT, EVENT_LABEL.LINK_PRIVACY)
const handleLinkTermsClicked = () => GA.trackEvent(LINK, EXIT, EVENT_LABEL.LINK_TERMS)
</script>

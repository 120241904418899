import { getPostList } from '@/api/UserPosts'
import { getPostListWithTag } from '@/api/UserTags'
import MUTATION_TYPES from '@/store/mutations/types'
import TAG_CONSTANTS from '@/constants/tags'

export default async({ commit }, { page, tagSlug }) => {
    commit(MUTATION_TYPES.SET_POST_LIST_IS_LOADING, true)
    try {
        let res
        if (tagSlug === TAG_CONSTANTS.DEFAULT_FEED_TAG.slug) {
            res = await getPostList(page)
        } else {
            res = await getPostListWithTag(page, tagSlug)
        }
        res.data.success = true
        commit(MUTATION_TYPES.SET_POST_LIST, res.data.data)
        commit(MUTATION_TYPES.SET_POST_TOTAL_NUMBER, res.data.meta.total)
        commit(MUTATION_TYPES.SET_POST_LIST_IS_LOADING, false)
        return res.data
    } catch (e) {
        commit(MUTATION_TYPES.SET_POST_LIST_IS_LOADING, false)
        if (!e.response) return e
        return e.response.data
    }
}

<template>
    <Transition name="disclaimer" appear>
        <div
            v-if="isShowCookiesDisclaimer"
            class="disclaimer-overlay"
        >
            <div
                class="disclaimer-wrapper"
                @click.self="closeDisclaimerHandler"
            >
                <div
                    class="cookie-disclaimer"
                    :class="disclaimerWidthClass"
                >
                    <Transition name="customise" mode="out-in">
                        <AppCookieCustomise v-if="isOpenCustomise" />
                        <AppCookieBanner v-else />
                    </Transition>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { computed } from 'vue'
import AppCookieBanner from '@/components/AppCookieDisclaimer/elements/AppCookieBanner.vue'
import AppCookieCustomise from '@/components/AppCookieDisclaimer/elements/AppCookieCustomise.vue'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'

const {
    isOpenCustomise,
    isShowCookiesDisclaimer,
    closeDisclaimerHandler,
} = useCookiesModal()

const disclaimerWidthClass = computed(() => `cookie-disclaimer--${isOpenCustomise.value ? 'tiny' : 'wide'}`)
</script>

<button
    class="button"
    :class="buttonClassObj"
    :style="buttonStyleObj"
>
    <template v-if="loading">
        <spinner class="button__spinner"></spinner>
    </template>
    <template v-else>
        <icon-delete v-if="isIconDelete"></icon-delete>
        <icon-edit v-if="isIconEdit"></icon-edit>
        <icon-calendar v-if="isIconCalendar"></icon-calendar>
        {{ text }}
    </template>
</button>

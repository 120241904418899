<app-post-header
    v-if="isHeader"
    :header-obj="block"
>
</app-post-header>
<app-post-paragraph
    v-if="isParagraph"
    :paragraph-obj="block"
>
</app-post-paragraph>
<app-post-image
    v-if="isImage"
    :image-obj="block"
>
</app-post-image>
<app-post-embed
    v-if="isEmbed"
    :embed-obj="block"
>
</app-post-embed>
<app-post-list
    v-if="isList"
    :list-obj="block"
>
</app-post-list>

const COOKIES_KEY = 'cookies_preferences'
const COOKIES_OPERATIONAL_KEY = 'operational'
const COOKIES_ADVERTISING_KEY = 'advertising'

const DEFAULT_COOKIES = {
    [COOKIES_OPERATIONAL_KEY]: true,
    [COOKIES_ADVERTISING_KEY]: false,
}

const ACCEPTED_ALL_COOKIES = {
    [COOKIES_ADVERTISING_KEY]: true,
    [COOKIES_OPERATIONAL_KEY]: true,
}

const COOKIE_LIFE_DURATION_DAYS = 365

export {
    COOKIES_KEY,
    COOKIES_OPERATIONAL_KEY,
    COOKIES_ADVERTISING_KEY,
    DEFAULT_COOKIES,
    ACCEPTED_ALL_COOKIES,
    COOKIE_LIFE_DURATION_DAYS,
}

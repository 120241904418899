import ACTION_TYPES from './types'
import getPostList from './GetPostList'
import getPost from './GetPost'
import getLatestVideo from './GetLatestVideo'
import getLatestPosts from './GetLatestPosts'
import getTags from './GetTags'

export default {
    [ACTION_TYPES.GET_POST_LIST]: getPostList,
    [ACTION_TYPES.GET_POST]: getPost,
    [ACTION_TYPES.GET_LATEST_VIDEO]: getLatestVideo,
    [ACTION_TYPES.GET_LATEST_POSTS]: getLatestPosts,
    [ACTION_TYPES.GET_TAGS]: getTags
}

<div class="post-list">
    <template v-if="!postListIsLoading">
        <app-post-preview
            v-for="post in postList"
            :key="post.id"
            :previewObject="post"
        ></app-post-preview>
    </template>
    <template v-else>
        <app-post-preview
            v-for="post in skeletonsCount"
            :key="`post_preview_skeleton_${post}`"
            :previewObject="post"
            :loading="true"
        ></app-post-preview>
    </template>
    <app-post-pagination
        v-if="!listIsEmpty"
        :page="page"
    >
    </app-post-pagination>
</div>

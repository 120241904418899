<template>
    <div v-if="!IS_PRODUCTION" class="debug">
    <!-- сюда можно вставить инструменты дебага -->
    </div>
    <AppCookieDisclaimer />
    <AppHeader />
    <div class="content content_flex content_user">
        <router-view />
    </div>
    <AppFooter />
</template>

<script setup>
import { provideI18n } from '@/plugins/i18nPlugin'
import { loadLocaleMessages } from '@/plugins/localeLoader'
import AppHeader from '@/components/AppHeader/AppHeader.vue'
import AppFooter from '@/components/AppFooter/AppFooter.vue'
import AppCookieDisclaimer from '@/components/AppCookieDisclaimer/AppCookieDisclaimer.vue'
import { IS_PRODUCTION } from '@/constants/envAppConfig'
import DOCUMENT_TITLE from '@/constants/documentTitles'

// загружаем локали
loadLocaleMessages()

document.title = DOCUMENT_TITLE.DEFAULT
// локализация
provideI18n({
    locale: 'en',
    messages: loadLocaleMessages()
})
</script>

<style lang="scss">
    @import '../styles/main';
</style>

import { createRouter, createWebHistory } from 'vue-router'
import PostFeed from '@/views/PostFeed/PostFeed.vue'
import OnePost from '@/views/OnePost/OnePost.vue'
import View404 from '@/views/PageNotFound/PageNotFound.vue'
import CookiePolicy from '@/views/CookiePolicy'
import { GA } from '@/modules/googleAnalytics'
import COMMON_ROUTES, { SUPPORT_POST_ID } from '@/constants/router'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'
import URL from '@/constants/url'

const { initDisclaimerStatus } = useCookiesModal()

const routes = [
    ...COMMON_ROUTES,
    {
        path: '/page-not-found',
        name: '404',
        component: View404,
        beforeEnter: (to, from, next) => {
            GA.trackPage(to.fullPath, '404 Redirect')
            next()
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {
            name: '404'
        }
    },
    {
        path: '/feed/:tag/:page',
        name: 'Feed',
        component: PostFeed,
        props: true
    },
    {
        path: '/post',
        redirect: {
            name: 'Home'
        }
    },
    {
        path: '/post/:id',
        name: 'Post',
        component: OnePost,
        props: true,
    },
    {
        path: '/support',
        name: 'Support',
        component: OnePost,
        props: true,
        beforeEnter: (to, from, next) => {
            to.params.id = SUPPORT_POST_ID
            next()
        }
    },
    {
        path: '/cookie-policy',
        component: CookiePolicy,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to) => {
    initDisclaimerStatus(to.fullPath === URL.COOKIE_POLICY)
})

export default router

import client from './Client'

const END_POINT = '/latest-video/1'

const getLatestVideo = () => client.get(`${END_POINT}`)
// todo тут можно добавить методы

export {
    getLatestVideo
}

const BUTTON_TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    TEXT: 'text'
}

const BUTTON_SIZE = {
    NORMAL: 'normal',
    SMALL: 'small'
}

const BUTTON_ICONS = {
    DELETE: 'delete',
    CALENDAR: 'calendar',
    EDIT: 'edit'
}

export { BUTTON_TYPE, BUTTON_SIZE, BUTTON_ICONS }

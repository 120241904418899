import Cookies from 'js-cookie'
import { GA } from '@/modules/googleAnalytics'
import {
    COOKIES_KEY,
    COOKIES_ADVERTISING_KEY,
    COOKIE_LIFE_DURATION_DAYS,
} from '@/constants/cookies'

function getParsedCookies() {
    return JSON.parse(Cookies.get(COOKIES_KEY))
}

function checkExistCookie(key) {
    return Cookies.get(key) !== undefined
}

function checkExistCookiePreferences() {
    return checkExistCookie(COOKIES_KEY)
}

function setCookies(cookieData) {
    const isGAChanged = cookieData[COOKIES_ADVERTISING_KEY] !== isEnableGA()
    Cookies.set(COOKIES_KEY, JSON.stringify(cookieData), { expires: COOKIE_LIFE_DURATION_DAYS })
    if (isGAChanged) toggleGA()
}

function isEnableGA() {
    return checkExistCookiePreferences() && getParsedCookies()[COOKIES_ADVERTISING_KEY]
}

function toggleGA() {
    return isEnableGA() ? GA.init() : GA.disable()
}

function deleteCookie(key) {
    if (!checkExistCookie(key)) return
    Cookies.remove(key, { path: '/', domain: document.domain })
}

export {
    checkExistCookiePreferences,
    setCookies,
    getParsedCookies,
    isEnableGA,
    deleteCookie,
}

import { ref } from 'vue'
import {
    ACCEPTED_ALL_COOKIES,
    DEFAULT_COOKIES,
    COOKIES_ADVERTISING_KEY,
} from '@/constants/cookies'
import { setCookies, getParsedCookies, checkExistCookiePreferences } from '@/services/cookiesManager'

const isShowCookiesDisclaimer = ref(false)
const isOpenCustomise = ref(false)
const cookiePreferences = ref(DEFAULT_COOKIES)

export function useCookiesModal() {
    function initDisclaimerStatus(isCookiePolicyCurrentPage) {
        const isExistCookiePreferences = checkExistCookiePreferences()
        isShowCookiesDisclaimer.value = !isCookiePolicyCurrentPage && !isExistCookiePreferences
        isOpenCustomise.value = isExistCookiePreferences
        if (isExistCookiePreferences) cookiePreferences.value = getParsedCookies()
    }

    function showCookiesDisclaimer() {
        isShowCookiesDisclaimer.value = true
    }

    function hideCookiesDisclaimer() {
        isShowCookiesDisclaimer.value = false
    }

    function openCustomiseWindow() {
        isOpenCustomise.value = true
    }

    function hideCustomiseWindow() {
        isOpenCustomise.value = false
    }

    function acceptAllCookies() {
        setCookies(ACCEPTED_ALL_COOKIES)
        initDisclaimerStatus()
    }

    function setCookiesPreferences() {
        setCookies(cookiePreferences.value)
        initDisclaimerStatus()
    }

    function closeDisclaimerHandler() {
        return checkExistCookiePreferences() ? hideCookiesDisclaimer() : hideCustomiseWindow()
    }

    function changeAdvertisingPreferences(newValue) {
        cookiePreferences.value[COOKIES_ADVERTISING_KEY] = newValue
    }

    return {
        isOpenCustomise,
        isShowCookiesDisclaimer,
        cookiePreferences,
        initDisclaimerStatus,
        openCustomiseWindow,
        showCookiesDisclaimer,
        setCookiesPreferences,
        closeDisclaimerHandler,
        acceptAllCookies,
        changeAdvertisingPreferences,
    }
}

const LOCAL_STORAGE_KEY = 'keycloakToken'
const BASE_ACCESS_ROLE = 'admin'

const INIT_OPTIONS = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    onLoad: 'login-required',
}

const DEFAULT_TOKEN_DATA = {
    token: '',
    refreshToken: '',
}

const MIN_VALIDITY_UPDATE_TOKEN_VALUE_SEC = -1

const ERRORS = {
    EXPIRED_TOKEN: 'Expired token.'
}

export {
    BASE_ACCESS_ROLE,
    LOCAL_STORAGE_KEY,
    INIT_OPTIONS,
    DEFAULT_TOKEN_DATA,
    MIN_VALIDITY_UPDATE_TOKEN_VALUE_SEC,
    ERRORS,
}

import { createStore } from 'vuex'
import mutations from './mutations/index'
import actions from './actions/index'

export default createStore({
    state: {
        postList: [],
        postListIsLoading: false,
        postTotalNumber: 0,
        currentPost: {},
        currentPostIsLoading: false,
        latestVideo: {},
        latestPosts: [],
        tags: []
    },
    mutations,
    actions,
    modules: {
    }
})

import { useI18n } from '@/plugins/i18nPlugin'
import LogoDiscord from '@/assets/svg/iconDiscord.svg'
import LogoYoutube from '@/assets/svg/iconYoutube.svg'
import URL from '@/constants/url'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY } from '@/constants/analytics'

export default {
    name: 'Social',
    components: {
        LogoDiscord,
        LogoYoutube
    },
    setup() {
        const i18n = useI18n()
        const urlPlay = URL.HERO_WARS_HOME
        const urlDiscord = URL.DISCORD
        const urlYoutube = URL.YOUTUBE
        const { BUTTON } = EVENT_ACTION
        const { EXIT } = EVENT_CATEGORY
        const handleDiscordClicked = () => GA.trackEvent(BUTTON, EXIT, 'discord')
        const handleYoutubeClicked = () => GA.trackEvent(BUTTON, EXIT, 'youtube')
        const handlePlayClicked = () => GA.trackEvent(BUTTON, EXIT, 'play hero wars')

        return {
            i18n,
            urlPlay,
            urlDiscord,
            urlYoutube,
            handleDiscordClicked,
            handleYoutubeClicked,
            handlePlayClicked
        }
    }

}

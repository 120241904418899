import AppWidgetSocial from '@/components/AppWidgetSocial/AppWidgetSocial.vue'
import AppWidgetLatestVideo from '@/components/AppWidgetLatestVideo/AppWidgetLatestVideo.vue'
import AppWidgetLatestPosts from '@/components/AppWidgetLatestPosts/AppWidgetLatestPosts.vue'
import AppPost from '@/components/AppPost/AppPost.vue'

export default {
    name: 'OnePost',
    components: {
        AppWidgetSocial,
        AppWidgetLatestVideo,
        AppWidgetLatestPosts,
        AppPost
    },
    props: ['id'],
    setup() {
        return {
        }
    }
}

import { getPost } from '@/api/UserPosts'
import { getPost as getPostAdmin } from '@/api/AdminPosts'
import MUTATION_TYPES from '@/store/mutations/types'
import { IS_ADMIN_APP } from '@/constants/envAppConfig';

export default async({ commit }, { id }) => {
    commit(MUTATION_TYPES.SET_CURRENT_POST_IS_LOADING, true)
    try {
        let res
        if (IS_ADMIN_APP) {
            res = await getPostAdmin(id)
        } else {
            res = await getPost(id)
        }
        commit(MUTATION_TYPES.SET_CURRENT_POST, res.data.data)
        commit(MUTATION_TYPES.SET_CURRENT_POST_IS_LOADING, false)
        res.data.success = true
        return res.data
    } catch (e) {
        console.error(e)
        commit(MUTATION_TYPES.SET_CURRENT_POST_IS_LOADING, false)
        if (!e.response) return e
        return e.response.data
    }
}

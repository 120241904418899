import { useRouter } from 'vue-router'
import { useI18n } from '@/plugins/i18nPlugin'
import { GA } from '@/modules/googleAnalytics'
import AppButton from '@/components/AppButton/AppButton.vue'
import { EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL } from '@/constants/analytics'

export default {
    name: 'PageNotFound',
    components: {
        AppButton
    },
    setup() {
        const i18n = useI18n()
        const router = useRouter()
        const handleMainPageClicked = async() => {
            GA.trackEvent(EVENT_ACTION.BUTTON, EVENT_CATEGORY.NAVIGATION, EVENT_LABEL.BACK_TO_MAIN_PAGE)
            await router.push({ name: 'Home' })
        }
        return {
            i18n,
            handleMainPageClicked
        }
    }
}

import { computed } from 'vue'

export default function addTargetBlankToLinks(htmlText) {
    const htmlTextWithTargetBlank = computed(() => {
        return htmlText.value.replace(/<a href/g, '<a target="_blank" href')
    })
    return {
        htmlTextWithTargetBlank
    }
}

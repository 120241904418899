<template>
    <div class="tag-popover">
        <router-link
            v-if="isOneTag"
            class="tag-popover__tag tag-popover__tag_big"
            :style="getTagStyle(tagsMapped[0])"
            :to="getTagPath(tagsMapped[0].slug)"
            @click.stop="handleTagClicked(tagsMapped[0].title)"
        >
            {{ tagsMapped[0].title }}
        </router-link>
        <div
            v-else
            class="tag-popover__group"
        >
            <router-link
                v-for="tag in tagsMapped"
                :key="tag.id"
                class="tag-popover__tag tag-popover__tag_small"
                :to="getTagPath(tag.slug)"
                :style="getTagStyle(tag)"
                @click.stop="handleTagClicked(tag.title)"
            >
                <span class="tag-popover__text">{{ tag.title }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppTagPopover',
}
</script>

<script setup>
import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY } from '@/constants/analytics'

const props = defineProps({
    tags: {
        type: Array,
        default: () => []
    }
})
const store = useStore()
const allTags = computed(() => store.state.tags)
const tagsMapped = computed(() => {
    if (allTags.value.length === 0) return []
    return props.tags.reduce((currentList, tag) => {
        const tagInStore = allTags.value.find(storeTag => {
            return storeTag.id === tag.id
        })
        if (!tagInStore) return
        currentList.push({
            id: tagInStore.id,
            color: tagInStore?.attributes?.color_code || '',
            slug: tagInStore?.attributes?.slug,
            title: tagInStore?.attributes?.title
        })
        return currentList
    }, [])
})
const isOneTag = computed(() => tagsMapped.value.length === 1)

const handleTagClicked = (tagName) => GA.trackEvent(EVENT_ACTION.LINK, EVENT_CATEGORY.NAVIGATION, tagName)

const getTagPath = (tagSlug) => `/feed/${tagSlug}`
const getTagStyle = (tag) => `background-color: ${tag.color};`

</script>

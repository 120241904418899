<figure class="post__block">
    <img
        class="post__image"
        :class="imageClassObj"
        :src="imageUrl"
        alt="picture"
    >
    <figcaption
        class="post__caption"
        v-if="showCaption"
    >
        {{ caption }}
    </figcaption>
</figure>


import baseKeycloak from 'keycloak-js'
import {
    BASE_ACCESS_ROLE,
    LOCAL_STORAGE_KEY,
    INIT_OPTIONS,
    DEFAULT_TOKEN_DATA,
    MIN_VALIDITY_UPDATE_TOKEN_VALUE_SEC,
} from '@/constants/keycloakAuth'
import store from '@/store'
import MODULES_TYPES from '@/store/modules/types'
import ACTION_TYPES_ADMIN from '@/store/modules/admin/actions/types'

class Keycloak extends baseKeycloak {
    setup() {
        const storageAuthData = localStorage.getItem(LOCAL_STORAGE_KEY)
        const authData = storageAuthData ? JSON.parse(storageAuthData) : DEFAULT_TOKEN_DATA
        return this.init({
            onLoad: INIT_OPTIONS.onLoad,
            token: authData.token,
            refreshToken: authData.refreshToken,
        }).then(this.loginToApp)
    }

    get tokenData() {
        return {
            token: this.token,
            refreshToken: this.refreshToken,
        }
    }

    get baseAccess() {
        return !!this.resourceAccess[this.clientId]?.roles?.includes(BASE_ACCESS_ROLE)
    }

    async loginToApp() {
        await store.dispatch(`${MODULES_TYPES.ADMIN}/${ACTION_TYPES_ADMIN.LOGIN}`)
    }

    forceUpdateToken() {
        this.updateToken(MIN_VALIDITY_UPDATE_TOKEN_VALUE_SEC).then(this.loginToApp)
    }
}

export default new Keycloak(INIT_OPTIONS)

import { computed } from 'vue'
import addTargetBlankToLinks from '@/composables/addTargetBlankToLinks'

export default {
    name: 'BlockHeader',
    props: {
        headerObj: Object
    },
    setup(props) {
        const classObj = computed(() => {
            return {
                post__header_2: props.headerObj.data.level === 2,
                post__header_3: props.headerObj.data.level === 3
            }
        })
        const text = computed(() => props.headerObj.data.text)
        const { htmlTextWithTargetBlank } = addTargetBlankToLinks(text)

        return {
            classObj,
            htmlTextWithTargetBlank
        }
    }
}

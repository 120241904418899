import AppPostPreview from './AppPostPreview/AppPostPreview.vue'
import AppPostPagination from './AppPostPagination/AppPostPagination.vue'
import { computed, watchEffect, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import ACTION_TYPES from '@/store/actions/types'
import POST_CONSTANTS from '@/constants/posts'

export default {
    name: 'PostList',
    components: {
        AppPostPreview,
        AppPostPagination
    },
    props: ['page', 'tag'],
    setup(props) {
        const store = useStore()
        const router = useRouter()
        const postList = computed(() => store.state.postList)
        const postListIsLoading = computed(() => store.state.postListIsLoading)
        const skeletonsCount = POST_CONSTANTS.POSTS_ON_PAGES
        const listIsEmpty = computed(() => {
            if (!postList.value) return true
            return postList.value.length === 0
        })

        // грузим посты
        const watchStopper = watchEffect(async() => {
            const result = await store.dispatch(ACTION_TYPES.GET_POST_LIST, {
                page: props.page,
                tagSlug: props.tag
            })
            if (!result.success) {
                await router.replace({
                    name: '404'
                })
            }
        })

        onBeforeUnmount(() => watchStopper())

        return {
            postList,
            listIsEmpty,
            postListIsLoading,
            skeletonsCount
        }
    }
}

import { computed } from 'vue'
import AppPostListText from './AppPostListText/AppPostListText.vue'

export default {
    name: 'BlockList',
    components: {
        AppPostListText
    },
    props: {
        listObj: Object
    },
    setup(props) {
        const isOrdered = computed(() => props.listObj.data.style === 'ordered')
        const list = computed(() => props.listObj.data.items ?? [])

        return {
            isOrdered,
            list
        }
    }
}

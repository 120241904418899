<template v-if="loading">
    <div class="preview">
        <div class="preview__image skeleton">
            <div class="skeleton__loader"></div>
        </div>
        <div class="preview__text-content">
            <div class="skeleton skeleton_preview-date">
                <div class="skeleton__loader"></div>
            </div>
            <div class="skeleton skeleton_preview-title">
                <div class="skeleton__loader"></div>
            </div>
            <div class="skeleton skeleton_preview-title skeleton_preview-title_short">
                <div class="skeleton__loader"></div>
            </div>
            <div class="skeleton skeleton_preview-text">
                <div class="skeleton__loader"></div>
            </div>
            <div class="skeleton skeleton_preview-text skeleton_preview-text_short">
                <div class="skeleton__loader"></div>
            </div>
            <div class="skeleton skeleton_preview-text">
                <div class="skeleton__loader"></div>
            </div>
        </div>
    </div>
</template>
<template v-else>
    <a class="preview" @click="handlePreviewClicked">
        <AppTagPopover :tags="postTags" />
        <img
            class="preview__image"
            :src="postImageUrl"
            loading="lazy"
            alt="post-image"
        >
        <div class="preview__text-content">
            <p class="preview__date">{{ postDate }}</p>
            <h3 class="preview__title">{{ postTitle }}</h3>
            <p class="preview__text" v-html="postPreview"></p>
        </div>
    </a>
</template>

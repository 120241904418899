<template>
    <div class="cookie-banner">
        <div class="cookie-banner__description">
            <p class="cookie-banner__text">
                {{ i18n.$t('COOKIE_DISCLAIMER_TEXT_1') }}
                <a
                    :href="URL.COOKIE_POLICY"
                    target="_blank"
                >
                    {{ i18n.$t('COOKIE_LINK_TEXT') }}
                </a>.
            </p>
            <p class="cookie-banner__text">
                {{ i18n.$t('COOKIE_DISCLAIMER_TEXT_2') }}
            </p>
        </div>
        <div class="cookie-banner__controls">
            <button
                class="button button_primary button_small cookie-banner__button--primary"
                @click="acceptAllCookies"
            >
                {{ i18n.$t('COOKIE_ACCEPT_ALL_BUTTON') }}
            </button>
            <button
                class="button cookie-banner__button--ghost"
                @click="openCustomiseWindow"
            >
                {{ i18n.$t('COOKIE_CUSTOMISE_BUTTON') }}
            </button>
        </div>
    </div>
</template>

<script setup>
import URL from '@/constants/url'
import { useCookiesModal } from '@/composables/cookiesDisclaimerModal'
import { useI18n } from '@/plugins/i18nPlugin'

const i18n = useI18n()

const {
    openCustomiseWindow,
    acceptAllCookies,
} = useCookiesModal()
</script>

import { computed } from 'vue'

export default {
    name: 'BlockImage',
    props: {
        imageObj: Object
    },
    setup(props) {
        const imageUrl = computed(() => props.imageObj.data.file.url)
        const caption = computed(() => props.imageObj.data.caption)
        const isStretched = computed(() => props.imageObj.data.stretched)
        const showCaption = computed(() => caption.value !== '')

        const imageClassObj = computed(() => {
            return {
                post__image_stretched: isStretched.value,
                post__image_filled: !isStretched.value
            }
        })

        return {
            imageUrl,
            showCaption,
            caption,
            imageClassObj
        }
    }
}

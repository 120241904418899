const PROCESS_TYPE = process.env.VUE_APP_TYPE
const PROCESS_ENV = process.env.NODE_ENV
const S3_BUCKET_DIRECTORY_PATH = `${process.env.VUE_APP_S3_BUCKET_BASE_PATH}/${process.env.VUE_APP_BUCKET_TARGET_PATH}/`
const IS_PRODUCTION = PROCESS_ENV === 'production'
const IS_USER_APP = PROCESS_TYPE === 'app-user'

const IS_ADMIN_APP = PROCESS_TYPE === 'app-admin'
const IS_DEVELOPMENT_MODE = PROCESS_ENV === 'development'
const STATIC_RESOURCES_PATH = IS_PRODUCTION && IS_USER_APP ? `${S3_BUCKET_DIRECTORY_PATH}` : '/'

export {
    IS_PRODUCTION,
    IS_ADMIN_APP,
    IS_DEVELOPMENT_MODE,
    STATIC_RESOURCES_PATH,
}

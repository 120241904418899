import { IS_ADMIN_APP, IS_DEVELOPMENT_MODE } from '@/constants/envAppConfig'
import { deleteCookie } from '@/services/cookiesManager'

class GoogleAnalytics {
    trackingID = process.env.VUE_APP_GA_TRACKING_ID
    cookieDomain = process.env.VUE_APP_GA_COOKIE_DOMAIN
    url = `https://www.googletagmanager.com/gtag/js?id=${this.trackingID}`
    isScriptLoaded = false
    isCollectRestricted = IS_ADMIN_APP || IS_DEVELOPMENT_MODE
    dataLayerName = 'dataLayer'
    isDataLayerRegister = false
    cookieCommon = '_ga'
    cookieId = `_ga_${process.env.VUE_APP_GA_TRACKING_ID.replace('G-', '')}`

    gtag() {
        window[this.dataLayerName].push(arguments)
    }

    event(eventName, params) {
        this.gtag('event', eventName, params)
    }

    loadScript() {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script')
            script.async = true
            script.src = this.url
            document.head.appendChild(script)
            script.onload = resolve
            script.onerror = reject
        })
    }

    registerGlobalObject() {
        if (!window[this.dataLayerName]) window[this.dataLayerName] = []
        this.isDataLayerRegister = true
    }

    async init() {
        if (IS_DEVELOPMENT_MODE) return
        this.registerGlobalObject()
        if (!this.isScriptLoaded) await this.loadScript()
        this.isScriptLoaded = true
        this.dataCollectionStartup()
    }

    dataCollectionStartup() {
        this.gtag('js', new Date())
        this.gtag('config', this.trackingID, { cookie_domain: this.cookieDomain })
    }

    disable() {
        // Needs to listen unload event cause gtag add cookie again after manual deleting
        window.addEventListener('unload', () => {
            this.deleteCookies()
        })
        this.deleteCookies()
    }

    deleteCookies() {
        deleteCookie(this.cookieCommon)
        deleteCookie(this.cookieId)
    }

    trackPage(path, title = '') {
        if (this.isCollectRestricted || !this.isDataLayerRegister) return
        this.event('page_view', {
            page_title: title || document.title,
            page_path: path,
            page_location: document.location.href
        })
    }

    trackEvent(action, category, label) {
        if (this.isCollectRestricted || !this.isDataLayerRegister) return
        this.event(action, {
            event_category: category,
            event_label: label,
        })
    }

}

export const GA = new GoogleAnalytics()

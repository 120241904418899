/*
** Компонент кнопки, универсальный на весь проект.
*  Все кнопки делятся по:
*       - размеру: стандартные и маленькие
*       - типу: яркая оранжевая, второстепенная серая и текстовая
*
*  Слева от текста в кнопке может быть иконка, тогда нужно как пропс указать её название
*/

import { computed } from 'vue'
import { BUTTON_SIZE, BUTTON_TYPE, BUTTON_ICONS } from '@/constants/buttons'
import Spinner from '@/assets/svg/spinner.svg'
import IconDelete from '@/assets/svg/iconDelete.svg'
import IconCalendar from '@/assets/svg/iconCalendar.svg'
import IconEdit from '@/assets/svg/iconEdit.svg'

export default {
    name: 'Button',
    components: {
        Spinner,
        IconDelete,
        IconCalendar,
        IconEdit
    },
    props: {
        text: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: BUTTON_SIZE.NORMAL
        },
        type: {
            type: String,
            default: BUTTON_TYPE.PRIMARY
        },
        icon: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: 'auto'
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const isSizeNormal = computed(() => props.size === BUTTON_SIZE.NORMAL)
        const isSizeSmall = computed(() => props.size === BUTTON_SIZE.SMALL)

        const isTypePrimary = computed(() => props.type === BUTTON_TYPE.PRIMARY)
        const isTypeSecondary = computed(() => props.type === BUTTON_TYPE.SECONDARY)
        const isTypeText = computed(() => props.type === BUTTON_TYPE.TEXT)

        const isIconDelete = computed(() => props.icon === BUTTON_ICONS.DELETE)
        const isIconCalendar = computed(() => props.icon === BUTTON_ICONS.CALENDAR)
        const isIconEdit = computed(() => props.icon === BUTTON_ICONS.EDIT)

        const buttonClassObj = computed(() => {
            return {
                [`button_${BUTTON_SIZE.NORMAL}`]: isSizeNormal.value,
                [`button_${BUTTON_SIZE.SMALL}`]: isSizeSmall.value,
                [`button_${BUTTON_TYPE.PRIMARY}`]: isTypePrimary.value,
                [`button_${BUTTON_TYPE.SECONDARY}`]: isTypeSecondary.value,
                [`button_${BUTTON_TYPE.TEXT}`]: isTypeText.value
            }
        })

        const buttonStyleObj = computed(() => {
            return {
                width: props.width
            }
        })

        return {
            isIconDelete,
            isIconCalendar,
            isIconEdit,
            buttonClassObj,
            buttonStyleObj
        }
    }
}

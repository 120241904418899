<div class="widget-social">
    <div class="widget-social__link-list">
        <a
            @click="handleDiscordClicked"
            class="widget-social__link widget-social__link_discord"
            :href="urlDiscord"
            target="_blank"
        >
            <logo-discord class="widget-social__logo"></logo-discord>
        </a>
        <a
            @click="handleYoutubeClicked"
            class="widget-social__link widget-social__link_youtube"
            :href="urlYoutube"
            target="_blank"
        >
            <logo-youtube class="widget-social__logo"></logo-youtube>
        </a>
    </div>
    <a
        @click="handlePlayClicked"
        class="widget-social__link widget-social__link_play"
        :href="urlPlay"
        target="_blank"
    >
        {{ i18n.$t( 'PLAY_BUTTON' ) }}
    </a>
</div>

import TAG_CONSTANTS from '@/constants/tags'
import { hasAccess } from '@/middleware'

const COMMON_ROUTES = [
    {
        path: '/',
        name: 'Home',
        redirect: `/feed/${TAG_CONSTANTS.DEFAULT_FEED_TAG.slug}/1`,
        meta: {
            middleware: [hasAccess]
        }
    },
    {
        path: '/feed',
        redirect: `/feed/${TAG_CONSTANTS.DEFAULT_FEED_TAG.slug}/1`,
        meta: {
            middleware: [hasAccess]
        }
    },
    {
        path: '/feed/:page(\\d+)',
        redirect: to => {
            return `/feed/${TAG_CONSTANTS.DEFAULT_FEED_TAG.slug}/${to.params.page}`
        },
        meta: {
            middleware: [hasAccess]
        }
    },
    {
        path: '/feed/:tag',
        redirect: to => {
            return `/feed/${to.params.tag}/1`
        },
        meta: {
            middleware: [hasAccess]
        }
    }
]

export default COMMON_ROUTES

export const SUPPORT_POST_ID = '1519'

<div class="post__block post__list">
    <ol v-if="isOrdered">
        <li v-for="(item, i) in list">
            <app-post-list-text :text-obj="item"></app-post-list-text>
        </li>
    </ol>
    <ul v-else>
        <li v-for="(item, i) in list">
            <app-post-list-text :text-obj="item"></app-post-list-text>
        </li>
    </ul>
</div>

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const ignoreErrorsLists = {
    regExp: [
        /window.cb\d* is not a function/gmi
    ],
    title: [
        'extension',
        'Extension',
        'jQuery',
        'Request aborted',
        'Unexpected token \'else\'',
        'checkPopUp',
        'parseChainId',
    ],
    description: [
        'Non-Error',
        'AbortError: The operation was aborted', // Firefox Dev edition
        'No error',
        'Extension',
        'extension',
        'Unexpected token \'<\'', // ошибки браузерного парсинга, часто встречается на ChromeOS
        'can\'t redefine non-configurable property "userAgent"', // это сама sentry пытается что-то сделать с userAgent
        'illegal character U+009E',
        'Cannot redefine property: BetterJsPop',
        'Cannot read property \'config\' of undefined',
        'Cannot read property \'didMutated\' of null',
        'Cannot read properties of null (reading \'didMutated\')',
        'Cannot read properties of null (reading \'disconnect\')',
        'Cannot read properties of null (reading \'deleteContents\')',
        'Cannot read property \'whitelist\' of undefined',
        'Cannot read properties of undefined (reading \'setConnectedRobot\')',
        'undefined is not an object (evaluating \'this.createEntity\')',
        'undefined is not an object (evaluating \'p.manager\')',
        'server_fp is not defined',
        'You called Tooltipster\'s "destroy" method on an uninitialized element',
        'webkitExitFullScreen',
        'ResizeObserver loop limit exceeded',
        'unSelectAll is not defined',
        'Identifier \'change_ua\' has already been declared',
        '__gCrWeb)', // chrome web view
        '__ybro', // yandex browser
        'UCShellJava.sdkEventFire is not a function',
        'SecurityError: Failed to read the \'cookie\' property from \'Document\': Access is denied for this document.',
        'window.java_obj.showSource is not a function',
        'Cannot redefine property: googletag',
        'e.apply is not a function',
        'this.remove is not a function',
        'Unexpected token \'else\'',
        'instantSearchSDKJSBridgeClearHighlight', // microsoft translator
        'processRandomSelector', // android vivo browser,
        'wsWhitelisted',
        'window.webkit.messageHandlers',
        'zaloJSV2 is not defined',
        'about:///page-not-found', // firefox 68 error
        'Permission denied to access property', // firefox permission error
        'Promise rejection value is a non-unwrappable cross-compartment wrapper', // firefox error on Windows 7
        /^Unexpected end of input$/, // yandex metrika init script error
        /^confirm.error is not a function$/, // chrome 79 desktop error
        /^No identifiers allowed directly after numeric literal$/, // yandex metrika init script error on firefox
        /^window.event is undefined; can't access its "keyCode" property$/, // old version firefox error

    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /chrome-extension/i,
        // Some trash analytics
        /zamant\.ru/i,
        /maralo\.ru/i,
        // google static
        /gstatic\.com/i,

        /translate\.googleapis\.com/i,
        /mc\.yandex\.ru/i
    ]
}

export default function setupSentry(app) {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new BrowserTracing()
        ],
        environment: process.env.NODE_ENV,
        beforeSend(event, hint) {
            // убираем все ошибки из Google Analytics
            const isFromGtag = (exeption) => {
                if (!exeption) return false
                if (!exeption.target) return false
                if (!exeption.target.src) return false
                return exeption.target.src.indexOf('www.googletagmanager.com/gtag') !== -1
            }

            const description = hint?.originalException?.stack
            const message = hint?.originalException?.message
            let canSendEvent = true
            if (description) {
                ignoreErrorsLists.title.forEach((title) => {
                    if (description.toLowerCase().indexOf(title.toLowerCase()) !== -1) {
                        canSendEvent = false
                    }
                })
            }
            ignoreErrorsLists.regExp.forEach((regExp) => {
                if (description && regExp.test(description)) {
                    canSendEvent = false
                }
                if (message && regExp.test(message)) {
                    canSendEvent = false
                }
            })

            if (canSendEvent && !isFromGtag(hint.originalException)) {
                return event
            }
        },
        ignoreErrors: ignoreErrorsLists.description,
        denyUrls: ignoreErrorsLists.denyUrls,
        tracesSampleRate: 1.0,
        trackComponents: true
    })
}

const EVENT_CATEGORY = {
    NAVIGATION: 'navigation',
    EXIT: 'exit'
}

const EVENT_ACTION = {
    LINK: 'link',
    BUTTON: 'button'
}

const EVENT_LABEL = {
    HW_LOGO: 'hero wars logo',
    FEED_PAGE: 'feed page',
    FEED_PAGE_NEXT: 'feed page next',
    FEED_PAGE_PREV: 'feed page prev',
    LINK_SUPPORT: 'support',
    LINK_PRIVACY: 'privacy policy',
    LINK_TERMS: 'terms of services',
    BACK_TO_MAIN_PAGE: 'main page'
}

export { EVENT_CATEGORY, EVENT_ACTION, EVENT_LABEL }

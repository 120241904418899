<figure class="post__block">
    <iframe
        class="post__embed"
        :src="embedSrc"
        frameborder="0"
        allowfullscreen
    >
    </iframe>
    <figcaption
        class="post__caption"
        v-if="showCaption"
    >
        {{ caption }}
    </figcaption>
</figure>

import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from '@/plugins/i18nPlugin'
import AppTagPopover from '@/components/AppTagPopover'
import formatPostDate from '@/composables/formatPostDate'
import BLOCK_TYPE from '@/constants/blocks'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY } from '@/constants/analytics'

export default {
    name: 'PostPreview',
    components: {
        AppTagPopover
    },
    props: {
        previewObject: {},
        loading: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const i18n = useI18n()
        const router = useRouter()
        const postImageUrl = computed(() => props.previewObject.attributes.wallpaper ?? '')
        const postId = computed(() => props.previewObject.id ?? null)
        const postTitle = computed(() => props.previewObject.attributes.title ?? '')
        const postTags = computed(() => props.previewObject?.relationships?.tags?.data || [])
        const postPreview = computed(() => {
            // ищем первый заголовок или параграф
            const previewBlock = props.previewObject.attributes.body.find(block => {
                return (block.type === BLOCK_TYPE.HEADER) || (block.type === BLOCK_TYPE.PARAGRAPH)
            })
            if (!previewBlock) return ''
            return previewBlock.data.text.replace(/<a href/g, '<a disabled onclick="return false" href')
        })
        const postPublicationDate = computed(() => props.previewObject.attributes.publication_date)
        const { postDate } = formatPostDate(postPublicationDate, i18n.$t('DATE_FORMAT'))
        const postPath = computed(() => `/post/${postId.value}`)
        const handlePreviewClicked = async() => {
            GA.trackEvent(EVENT_ACTION.LINK, EVENT_CATEGORY.NAVIGATION, postTitle.value)
            await router.push(postPath.value)
        }
        return {
            postTags,
            postImageUrl,
            postId,
            postTitle,
            postPreview,
            postDate,
            postPath,
            handlePreviewClicked
        }
    }
}

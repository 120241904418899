import { computed } from 'vue'
import addTargetBlankToLinks from '@/composables/addTargetBlankToLinks'

export default {
    name: 'BlockListText',
    props: ['textObj'],
    setup(props) {
        const data = computed(() => props.textObj)
        const { htmlTextWithTargetBlank } = addTargetBlankToLinks(data)
        return {
            htmlTextWithTargetBlank
        }
    }
}

import { computed } from 'vue'

export default {
    name: 'BlockEmbed',
    props: {
        embedObj: Object
    },
    setup(props) {
        const caption = computed(() => props.embedObj.data.caption)
        const showCaption = computed(() => caption.value !== '')
        const embedSrc = computed(() => props.embedObj.data.embed)
        const embedHeight = computed(() => props.embedObj.data.height)

        return {
            caption,
            showCaption,
            embedSrc,
            embedHeight
        }
    }
}

import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import iconAngleLeft from '@/assets/svg/iconAngleLeft.svg'
import iconAngleRight from '@/assets/svg/iconAngleRight.svg'
import POST_CONSTANTS from '@/constants/posts'
import { GA } from '@/modules/googleAnalytics'
import { EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL } from '@/constants/analytics'

export default {
    name: 'PostPagination',
    components: {
        iconAngleLeft,
        iconAngleRight
    },
    props: ['page'],
    setup(props) {
        const store = useStore()
        const router = useRouter()

        const buttonsNumber = POST_CONSTANTS.PAGINATION_MAX_BUTTONS
        const currentPage = computed(() => parseInt(props.page, 10))
        const postTotalNumber = computed(() => store.state.postTotalNumber)
        const lastPageNumber = computed(() => {
            return Math.ceil(postTotalNumber.value / POST_CONSTANTS.POSTS_ON_PAGES)
        })
        const buttonPrevHidden = computed(() => {
            return currentPage.value <= 1
        })
        const buttonNextHidden = computed(() => {
            return currentPage.value >= lastPageNumber.value
        })

        // расставляем видимые номера на кнопках
        const pagesView = computed(() => {
            const res = []
            // тривиальный случай (все кнопки влезают)
            if (lastPageNumber.value <= buttonsNumber) {
                for (let i = 1; i <= lastPageNumber.value; i++) {
                    res.push({
                        text: i,
                        number: i,
                        selected: i === currentPage.value,
                        disabled: i === currentPage.value
                    })
                }
                return res
            }

            // добавить текущую страницу
            res.push({
                text: `${currentPage.value}`,
                number: currentPage.value,
                selected: true,
                disabled: true
            })
            let freeSlots = buttonsNumber - 1
            let delta = 1

            // расставляем кнопки справа и слева
            while (freeSlots > 0) {
                const pointerRight = currentPage.value + delta
                if (pointerRight <= lastPageNumber.value) {
                    res.push({
                        text: pointerRight,
                        number: pointerRight,
                        selected: false,
                        disabled: false
                    })
                    freeSlots -= 1
                }

                if (freeSlots <= 0) continue

                const pointerLeft = currentPage.value - delta
                if (pointerLeft > 0) {
                    res.push({
                        text: pointerLeft,
                        number: pointerLeft,
                        selected: false,
                        disabled: false
                    })
                    freeSlots -= 1
                }

                delta += 1
            }
            res.sort((a, b) => a.number - b.number)
            // при необходимости ставим три точки, как в макете
            if (res[0].number > 1) {
                res[0].text = '...'
                res[0].disabled = true
            }
            if (res[buttonsNumber - 1].number < lastPageNumber.value) {
                res[buttonsNumber - 1].text = '...'
                res[buttonsNumber - 1].disabled = true
            }
            return res
        })
        const setCurrentPage = async(pageNumber) => {
            try {
                window.scrollTo(0, 0)
                await router.push({
                    name: 'Feed',
                    params: {
                        page: pageNumber
                    }
                })
            } catch (e) {
                console.error(e)
            }
        }
        const { BUTTON } = EVENT_ACTION
        const { NAVIGATION } = EVENT_CATEGORY
        const handlePageClicked = async(page) => {
            if (!page.disabled) {
                GA.trackEvent(BUTTON, NAVIGATION, `${EVENT_LABEL.FEED_PAGE} ${page.number}`)
                await setCurrentPage(page.number)
            }
        }
        const handlePrevClicked = async() => {
            if (currentPage.value > 1) {
                GA.trackEvent(BUTTON, NAVIGATION, EVENT_LABEL.FEED_PAGE_PREV)
                await setCurrentPage(currentPage.value - 1)
            }
        }
        const handleNextClicked = async() => {
            if (currentPage.value < lastPageNumber.value) {
                GA.trackEvent(BUTTON, NAVIGATION, EVENT_LABEL.FEED_PAGE_NEXT)
                await setCurrentPage(currentPage.value + 1)
            }
        }
        return {
            currentPage,
            pagesView,
            buttonPrevHidden,
            buttonNextHidden,
            handlePrevClicked,
            handleNextClicked,
            handlePageClicked
        }
    }
}
